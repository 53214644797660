:root {
  font-size: 10px;
}

* {
  padding: 0;
  margin: 0;
  font-family: 'Space Mono', monospace;
}

a {
  text-decoration: none;
  color: white;
}

body {
  background-image: url(./img/background-img.png);
  background-attachment: fixed;
  background-size: 110vh;
  background-repeat: repeat;
}
